import React from "react"
import Project from "./Project"
import styles from "./ProjectsList.module.scss"
import { graphql, useStaticQuery } from "gatsby"

const getProjects = graphql`
  query {
    allContentfulPridatNovyProjekt {
      projects: edges {
        node {
          slug
          nazevProjektu
          kratkyPopis
          hlavniFotka {
            description
            fluid(maxWidth: 500, quality: 50) {
              ...GatsbyContentfulFluid
            }
          }
          id
        }
      }
    }
  }
`

const ProjectsList = () => {
  const { allContentfulPridatNovyProjekt: { projects } } = useStaticQuery(getProjects)

  return (
    <section className={styles.section}>
      <div className={styles.projects}>
        {projects.map(project => (
          <Project key={project.node.id} project={project} />
        ))}
      </div>
    </section>
  )
}

export default ProjectsList
