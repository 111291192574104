import React from "react"
import styles from "./Project.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Project = ({ project }) => {
  const { nazevProjektu, slug, kratkyPopis, hlavniFotka } = project.node



  return (
    <Link to={`/projekty/${slug}`} className={styles.project}>
      <figure className={styles.imgBox}>
        <Img
          className={styles.img}
          fluid={hlavniFotka && hlavniFotka.fluid}
          alt={hlavniFotka && hlavniFotka.description}
        />
      </figure>
      <div className={styles.textWrapper}>
        <div className={styles.content}>
          <h3 className={styles.heading}>{nazevProjektu}</h3>
          <p className={styles.text}>{kratkyPopis} </p>
        </div>
        <div className={styles.button}>
          Zobrazit více <span className={styles.arrow}>→</span>
        </div>
      </div>
    </Link>
  )
}

export default Project

Project.propTypes = {
  project: PropTypes.object.isRequired,
}
