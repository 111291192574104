import React from 'react'
import ServiceBox from './ServiceBox'
import styles from './Services.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types';

const getLinks = graphql`
  query {
    allContentfulSluzby (sort:{fields: createdAt, order: ASC}) {
      servicesList : edges {
        node {
          id
          nazevSluzby
          kratkyPopis
          slug
        }
      }
    } 
  }   
`

const Services = ({page}) => {

  const { allContentfulSluzby : { servicesList } } = useStaticQuery(getLinks);

  return (
    <section style={{overflow: 'hidden'}} className={`${styles.section} ${page==='page' ? styles.sectionPage : ''}`}>
      {page==='page' && <h2 className={styles.heading}>Nabízíme</h2>}
      <div className={styles.services}>
        {servicesList.map(service => {
          return (
            <ServiceBox 
              key={service.node.id}
              name={service.node.nazevSluzby}
              desc={service.node.kratkyPopis}
              slug={service.node.slug}
            />)
        })}
      </div>       
    </section>
  )
}

export default Services

Services.propTypes = {
  page: PropTypes.string
}
