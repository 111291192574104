import React from "react"
import styles from "./ServiceBox.module.scss"
import { BsTools } from "react-icons/bs"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { WaterTap, Heater, Building, Gas, Boiler, Pipe } from "../icons/Icons"

const ServiceBox = ({ name, desc, slug }) => {
  let Icon
  switch (name) {
    case "Instalatérské práce":
      Icon = WaterTap
      break
    case "Plynařské práce":
      Icon = Gas
      break
    case "Stavební práce":
      Icon = Building
      break
    case "Servis a opravy kotlů":
      Icon = Boiler
      break
    case "Topenářské práce":
      Icon = Heater
      break
    case "Kanalizace":
      Icon = Pipe
      break
    default:
      Icon = BsTools
  }

  return (
    <Link to={`/${slug}`} className={styles.box}>
      <div className={styles.icon}>
        <Icon/>
      </div>
      <h3 className={styles.heading}>{name}</h3>
      <div className={styles.text}>{desc}</div>
    </Link>
  )
}

export default ServiceBox

ServiceBox.propTypes = {
  slug: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
