import React from "react"
import styles from "./Distributors.module.scss"
import SwiperComp from "../SwiperComp"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import dist from "../../data/sectionTexts"

const getDistributors = graphql`
  query {
    allContentfulDodavatele(sort: { fields: createdAt, order: ASC }) {
      distributors: edges {
        node {
          id
          mainText {
            mainText
          }
          logo {
            title
            fluid(maxWidth: 500, quality: 50) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const Distributors = ({ nomargin }) => {
  const {allContentfulDodavatele: { distributors }} = useStaticQuery(getDistributors)
  const { title, desc } = dist.distributors;

  return (
    <section className={`${styles.section} ${nomargin ? styles.nomargin : ""}`}>
      <h2 className={styles.heading}>
        {title.thin}{" "}<span className="bold">{title.bold}</span>
      </h2>
      {desc && <p className={styles.text}>{desc}</p>}
      <div className={styles.distributors}>
        <SwiperComp slidesPerView={5} alignCenter>
          {distributors.map(({node: {id, logo}}) => {
            return (
              <Img
                key={id}
                fluid={logo.fluid}
                alt={logo.title}
                className={styles.img}
              />
            )
          })}
        </SwiperComp>
      </div>
    </section>
  )
}

export default Distributors

Distributors.propTypes = {
  nomargin: PropTypes.bool,
}
