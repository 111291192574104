import React from "react"
import Layout from "../components/layout/Layout"
import Cta from "../components/sections/Cta"
import Experience from "../components/sections/Experience"
import Banner from "../components/header/Banner"
import Services from "../components/services/Services"
import ProjectsList from "../components/projects/ProjectsList"
import Distributors from "../components/sections/Distributors"
import SEO from "../components/SEO"

export default () => {
  return (
    <Layout>
      <SEO title='Realizace'/>
      <Banner text='Labore aliqua proident sunt cupidatat voluptate elit sit ex. Anim excepteur veniam nisi cillum anim sint nisi ullamco. Cupidatat eiusmod eu dolor ad excepteur pariatur ea culpa do aliqua voluptate.' heading='Realizace'/>
        <ProjectsList/>
      <Services page='page'/>
      <Cta text='velko'/>
      <Experience/>
      <Distributors/>
    </Layout>
    )
}
